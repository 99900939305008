import CategoryMenu from '../../components/content_category/category-menu.vue'

export default {
  name: 'content-show-page-container',

  components: {
    CategoryMenu
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      contentId: null,
      initializing: true,
      isMobileMenuShow: false,
      currentCategory: undefined,
      currentSubCategory: undefined,
      selectedCategoryId: undefined,
      defaultUsages: null
    }
  },

  watch: {
    currentCategory(newValue, oldValue) {
      if (oldValue === undefined) return // situation of initializing
      if (newValue && newValue.id === oldValue.id) return // situation of initializing

      if (newValue) {
        return this.backToCategoryIndex(newValue.id)
      } else {
        return this.backToCategoryIndex(oldValue.id)
      }
    },

    currentSubCategory(newValue, oldValue) {
      if (oldValue === undefined) return // situation of initializing
      if (newValue && newValue.id === oldValue.id) return // situation of initializing

      if (newValue) {
        return this.backToCategoryIndex(newValue.id)
      } else {
        return this.backToCategoryIndex(this.currentCategory.id)
      }
    }
  },

  computed: {
    pageInfos() {
      let data = {
        titleEn: 'NEWS',
        titleZh: this.copyLocaleText('nav_menu.news'),
        backToCategoryIndexPath: 'news_posts'
      }

      if (this.defaultUsages && this.defaultUsages.includes('about')) {
        data.titleEn = 'About Us'
        data.titleZh = this.copyLocaleText('nav_menu.about')
        data.backToCategoryIndexPath = 'abouts'
      }

      return data
    },

    categoriesTitle() {
      let result = ''
      if (this.currentCategory) result += this.currentCategory.name
      if (this.currentSubCategory)
        result += ` / ${this.currentSubCategory.name}`

      return result
    },

    isLoading() {
      return this.$store.getters['contents/isLoading']
    },

    content() {
      return this.$store.getters['contents/find'](this.contentId)
    },

    allCategories() {
      return this.$store.getters['categorySystems/allCategories']()
    }
  },

  // created() {},

  mounted() {
    this.contentId = this.$el.dataset.contentId
    this.defaultUsages = this.$el.dataset.defaultUsages.split(',')
    this.$store.dispatch('contents/find', this.contentId).then((_) => {
      this.selectedCategoryId = this.content.category.id
      this.categoriesLoadedHandler()
    })
  },

  methods: {
    openMobileMenu() {
      this.isMobileMenuShow = true
      // this.isMobileMenuShow = !this.isMobileMenuShow
    },

    categoriesLoadedHandler() {
      if (!this.selectedCategoryId) return

      const newCategory = this.allCategories.find(
        (category) => category.id === String(this.selectedCategoryId)
      )

      if (newCategory.isDataLoaded()) {
        this._assignCategoryToDataAccordingTo(newCategory)
      }
    },

    backToCategoryIndex(categoryId) {
      Turbolinks.visit(
        `/${this.pageInfos.backToCategoryIndexPath}?page[number]=1&page[size]=9&sort=-published_at&category=${categoryId}`
      )
    },

    _assignCategoryToDataAccordingTo(newCategory) {
      if (newCategory.depth === 3) {
        this.currentSubCategory = newCategory
        this.currentCategory = this.allCategories.find(
          (category) => category.id === String(newCategory.parent_id)
        )
      } else {
        this.currentCategory = newCategory
        this.currentSubCategory = null
      }
    }
  }
}
