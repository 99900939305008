<template lang="pug">
form.vc-order-cvs-selector(
  v-if="cvsStoreInfo"
  @submit.prevent=""
)
  .section-label
    span {{ copyLocaleText('cvs_information') }}

  .form-section
    p {{ cvsStoreInfo.cvs_store_name }}
    p {{ cvsStoreInfo.cvs_address }}
    p {{ cvsStoreInfo.cvs_telephone }}

    b-field(
      :type="errors.errorClassAt('cvs_store_info')"
      :message="errors.get('cvs_store_info')"
    )

    button.button.is-odd.is-outlined(
      href="#"
      target="_blank"
      ref="noopenner noreferrer"
      @click.prevent="getParamsAndGoForSelect()"
    )
      | {{ selectCvsBtnText }}
</template>

<script>
import queryString from 'query-string'
import expressMapSelectHelpersMixin from '@sharedMixins/express_map_select_helpers_mixin.js'

export default {
  // components: {},
  mixins: [expressMapSelectHelpersMixin],

  props: {
    selectedShippingMethodId: {
      type: [Number, String],
      required: true
    },

    cartService: {
      type: Object,
      required: true
    },

    order: {
      type: Object,
      required: true
    },

    form: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    selectCvsBtnText() {
      return this.actionLocaleText('select_x', {
        x: this.enumLocaleText(
          'shipping_method',
          'sub_logistic_type',
          this.shippingType
        )
      })
    },

    orderShipment() {
      return this.$store.getters['orderShipments/find'](
        this.order.shipments[0].id
      )
    },

    shippingMethod() {
      return this.$store.getters['shippingMethods/find'](
        this.selectedShippingMethodId
      )
    },

    shippingType() {
      return this.shippingMethod.shipment_type_detail.shipping_type
    },

    gatewayProvider() {
      return this.shippingMethod.gateway_type
    },

    errors() {
      return this.$store.getters['users/errors']
    },

    cvsStoreInfo() {
      return this.form.shipping_address.address.cvs_store_info
    }
  },

  mounted() {
    this.checkStoreInfo()
    this.tryToRestoreContactInfo().then((_) => {
      this.trySyncCvsStoreInfo()
    })
  },

  methods: {
    trySyncCvsStoreInfo() {
      let data = queryString.parse(window.location.search)

      if (!data.gateway) return

      let cvsDataUpdatable =
        this.gatewayProvider === data.gateway &&
        this.shippingType === data.ExtraData &&
        this.order.order_state === 'items_confirmed'

      if (cvsDataUpdatable) {
        this.syncStoreInfoToFrom(data)
        this.flagFormAsEdited()
        this.clearQueryString()
      }
    },

    checkStoreInfo() {
      if (
        this.cvsStoreInfo?.extra_data !==
        this.form.shipping_address.address.extra_data
      ) {
        this.resetStoreInfo()
      }
    },

    flagFormAsEdited() {
      this.$emit('form-edited')
    },

    syncStoreInfoToFrom(data) {
      this.form.shipping_address.address.cvs_store_info =
        this.convertMapInfo(data)

      this.errors.clear('cvs_store_info')
    },

    storeContactInfo() {
      let contactInfo = {
        email: this.form.email,
        shipping_address: this.form.shipping_address,
        billing_address: this.form.billing_address
      }

      this.$vlf.setItem('cart-contact-info', contactInfo)
    },

    tryToRestoreContactInfo() {
      return this.$vlf.getItem('cart-contact-info').then((contactInfo) => {
        if (!contactInfo) return
        this.form.email = contactInfo.email
        this.form.shipping_address = contactInfo.shipping_address
        this.form.billing_address = contactInfo.billing_address

        this.$vlf.setItem('cart-contact-info', null)
      })
    },

    getParamsAndGoForSelect() {
      if (!this.usingJdWindowOpenMap) this.storeContactInfo()

      this.cartService
        .expressMap(this.orderShipment, {
          device: this.isMobile ? 'mobile' : null
        })
        .then(() => {
          this.openExpressMap(this.expressMapUrl, [
            this.flagFormAsEdited,
            this.clearQueryString
          ])
        })
    },

    resetStoreInfo() {
      this.$set(this.form.shipping_address.address, 'cvs_store_info', {})
      this.flagFormAsEdited()
    }
  }
}
</script>
