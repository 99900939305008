import TappayForm from '../order/tappay-form.vue'
import TappayMixin from './tappay_mixin.js'

export default {
  components: {},

  mixins: [TappayMixin],

  props: {
    order: {
      type: Object,
      required: true
    },

    cartService: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isProcessing: false,
      isButtonBuilt: false,
      onsitePaymentData: {}
    }
  },

  computed: {
    paymentParams() {
      return this.$store.getters['orders/paymentParams']
    },

    gatewayApiBase() {
      return this.$store.getters['orders/gatewayApiBase']
    },

    isPayOnsite() {
      return this.order.payment_provider === 'tappay'
    },

    onsitePaymentRedirectPath() {
      return this.$store.getters['orderPayments/onsitePaymentRedirectPath']
    },

    onsitePaymentRedirectParams() {
      return this.$store.getters['orderPayments/onsitePaymentRedirectParams']
    },

    isPayByCreditCard() {
      return this.order.payment_type === 'credit_card'
    },

    isPayByLinePay() {
      return this.order.payment_type === 'line_pay'
    },

    isPayByJkoPay() {
      return this.order.payment_type === 'jko_pay'
    }
  },

  methods: {
    makePayment() {
      if (this.isPayOnsite) {
        this._makeOnsitePayment()
      } else {
        this._makeOffsitePayment()
      }
    },

    _makeOffsitePayment() {
      this.isProcessing = true
      this._ensurePaymentButtonWithCorrectParams()
        .then(() => {
          this.$refs['payment-form'].submit()
          setTimeout(() => {
            this.isProcessing = false
          }, 5000)
        })
        .catch((errors) => {
          this._errorHandler(errors)
          this.isProcessing = false
        })
    },

    _ensurePaymentButtonWithCorrectParams() {
      if (this.isButtonBuilt) return Promise.resolve(true)

      return this.cartService.makePayment(this.order).then((_) => {
        return this._buildPaymentButton()
      })
    },

    _buildPaymentButton() {
      const paymentForm = this.$refs['payment-form']

      Object.keys(this.paymentParams).map((key) => {
        let input = document.createElement('input')

        input.setAttribute('type', 'hidden')
        input.setAttribute('name', key)
        input.setAttribute('value', this.paymentParams[key])
        paymentForm.appendChild(input)
      })
      this.isButtonBuilt = true
    },

    _errorHandler(errors) {
      let response = errors.response

      if (response.status === 422) {
        switch (response.data.code) {
          case 'stock_not_enough_failure':
            return this._stockNotEnoughFailureHandler(errors)
          case 'gift_stock_not_enough_failure':
            return Turbolinks.visit('/cart')
        }
      }

      this.$emit('back-to-first-step')
    },

    _stockNotEnoughFailureHandler(errors) {
      const response = errors.response.data

      this.$buefy.snackbar.open({
        message: response.message,
        type: 'is-info',
        position: 'is-bottom-left',
        actionText: this.actionLocaleText('adjust_order_items'),
        indefinite: true,
        queue: false,
        onAction: () => {
          this.$emit('back-to-first-step')
        }
      })
    },

    _makeOnsitePayment() {
      this.isProcessing = true
      if (!this.order.user_credit_card_id) {
        if (this.isPayByCreditCard) {
          this._processOnsitePaymentByTappayForm()
        } else if (this.isPayByLinePay || this.isPayByJkoPay) {
          this._processOnsitePaymentByPrime()
        }
      } else {
        this._processOnsitePaymentByToken()
      }
    },

    _processOnsitePaymentByTappayForm() {
      this._setOnsitePaymentData('pay_by_prime')
      this.$buefy.modal.open({
        parent: this,
        component: TappayForm,
        props: {
          onsitePaymentData: this.onsitePaymentData
        },
        events: {
          'form-submitted': ({ afterSubmitHandler }) => {
            this._processOnsitePayment()
              .then((_) => {
                if (this.order.is_three_domain_secure_enabled) {
                  this._redirectPaymentUrl()
                } else {
                  afterSubmitHandler({
                    isSuccess: true
                  })
                  this._submitToThankYou()
                }
              })
              .catch((errors) => {
                afterSubmitHandler({
                  isSuccess: false,
                  code: errors.response.data.code,
                  message: errors.response.data.description
                })
                if (
                  errors.response.data.code !=
                  'credit_card_process_payment_failure'
                )
                  this._errorHandler(errors)
              })
          }
        },
        onCancel: () => {
          this.isProcessing = false
        }
      })
    },

    _processOnsitePaymentByPrime() {
      this._setOnsitePaymentData('pay_by_prime')
      this._getPrime()
        .then((_) => this._processOnsitePayment())
        .then((_) => {
          this._redirectPaymentUrl()
        })
        .catch((errors) => {
          if (
            errors.response.data.code === 'credit_card_process_payment_failure'
          ) {
            Turbolinks.visit(errors.response.data.order_url)
          } else {
            this._errorHandler(errors)
          }
        })
    },

    _processOnsitePaymentByToken() {
      this._setOnsitePaymentData('pay_by_token')
      this._processOnsitePayment()
        .then((_) => {
          if (this.order.is_three_domain_secure_enabled) {
            this._redirectPaymentUrl()
          } else {
            this._submitToThankYou()
          }
        })
        .catch((errors) => {
          if (
            errors.response.data.code === 'credit_card_process_payment_failure'
          ) {
            Turbolinks.visit(errors.response.data.order_url)
            this.$store.dispatch('addFlashMessage', [
              'notice',
              I18n.t('messages.failure.credit_card_pay_by_token_failure')
            ])
          } else {
            this._errorHandler(errors)
          }
        })
    },

    _processOnsitePayment() {
      return new Promise((resolve, reject) => {
        this.cartService
          .makePayment(this.order)
          .then((_) => {
            return this.$store.dispatch(
              'orderPayments/processOnsitePayment',
              this.onsitePaymentData
            )
          })
          .then((_) => {
            setTimeout(() => {
              this.isProcessing = false
            }, 5000)
            resolve()
          })
          .catch((errors) => {
            this.isProcessing = false
            reject(errors)
          })
      })
    },

    _setOnsitePaymentData(mode) {
      switch (mode) {
        case 'pay_by_prime':
          this.onsitePaymentData = {
            gateway_provider: this.order.payment_provider,
            payment_id: this.order.payment.id,
            mode: mode,
            prime: null,
            cardholder_name: this.order.billing_address.recipient,
            cardholder_email: this.order.email,
            cardholder_phone_number: this.order.billing_address.phone,
            remember_card: true,
            is_default: null
          }
          break
        case 'pay_by_token':
          this.onsitePaymentData = {
            gateway_provider: this.order.payment_provider,
            payment_id: this.order.payment.id,
            mode: mode
          }
          break
      }
    },

    _getPrime() {
      return new Promise((resolve, reject) => {
        this.setupTappay().then((_) => {
          let TPPay
          if (this.isPayByLinePay) {
            TPPay = TPDirect.linePay
          } else if (this.isPayByJkoPay) {
            TPPay = TPDirect.jkoPay
          }
          setTimeout((_) => {
            // setupTappay 需要時間
            TPPay.getPrime((result) => {
              if (result.status === 0) {
                this.onsitePaymentData.prime = result.prime
                resolve()
              }
            })
          }, 2000)
        })
      })
    },

    _submitToThankYou() {
      const paymentForm = this.$refs['payment-form']

      paymentForm.action = this.onsitePaymentRedirectPath
      Object.keys(this.onsitePaymentRedirectParams).map((key) => {
        let input = document.createElement('input')

        input.setAttribute('type', 'hidden')
        input.setAttribute('name', key)
        input.setAttribute('value', this.onsitePaymentRedirectParams[key])
        paymentForm.appendChild(input)
      })
      paymentForm.submit()
    },

    _redirectPaymentUrl() {
      // TPDirect.redirect(this.onsitePaymentRedirectPath)
      window.location.href = this.onsitePaymentRedirectPath
    }
  }
}
