import Cookies from 'js-cookie'
import PromoterEventStorageService from './promoter_event_storage_service.js'

const ORDER_INFO_KEY = 'cart/info'
const ORDER_ITEMS_KEY = 'cart/items'
const ORDER_ITEMS_EXPIRES_IN_KEY = 'cart/expires-in'
const CACHE_PERIOD = 20 * 60 // 20 minutes
const RESET_FLAG = 'reset_cart'

export default class ShoppingCartService {
  /**
   * constructor
   *
   * @param {Function} localForage 從 vue instance 傳入 _vm.$vlf
   * @param {Function} vuexStore 從 vue instance 傳入 _vm.$store
   */
  constructor(localForage, vuexStore) {
    this.localForage = localForage
    this.vuexStore = vuexStore
    this.promoterEventStorageService = new PromoterEventStorageService(
      localForage
    )
    this.promoterShareToken = null
    this._getPromoterShareToken()
  }

  /**
   * 取得目前使用者的 currentOrder
   * 會先檢查 localForage 中是否有 cache 住的內容。若有，會確認 cache 是否過期（暫存 20 分鐘）；若沒有，則透過 API 從 server 端
   * 取得正確內容，並將回應結果以及下次過期時間存到 localForage 中。
   *
   * @param {Object} options `force` 為 `true` 時可強制從 server 取得最新 currentOrder 資料，忽略 locale 端的 cache。
   * @memberof ShoppingCartService
   * @returns {Promise}
   */
  fetchCurrentOrder(
    options = {
      force: false
    }
  ) {
    return this._tryResetCurrentOrderLocalCache()
      .then(() => {
        return this.localForage.getItem(ORDER_ITEMS_KEY)
      })
      .then((items) => {
        if (items && !options.force) {
          return this.localForage
            .getItem(ORDER_ITEMS_EXPIRES_IN_KEY)
            .then((localCacheExpiredAt) => {
              if (
                localCacheExpiredAt &&
                localCacheExpiredAt < this._currentTime()
              )
                return this._fetchOrderItemFromServer()

              return this.vuexStore
                .dispatch('orderItems/storeStatesFromLocal', items)
                .then(() => {
                  return this.localForage.getItem(ORDER_INFO_KEY)
                })
                .then((currentOrderId) => {
                  return this.vuexStore.dispatch(
                    'orderItems/setCurrentOrderId',
                    currentOrderId
                  )
                })
            })
        } else {
          return this._fetchOrderItemFromServer()
        }
      })
  }

  addItemToCart(requestBody) {
    let requestBodyWithPromoterShareToken =
      this._mergeRequestBodyWithPromoterShareToken(requestBody)
    return this.vuexStore
      .dispatch('users/addItemToCart', requestBodyWithPromoterShareToken)
      .then(() => {
        return this._cacheOrderItemsToLocalForage()
      })
  }

  updateCartItem(cartItem) {
    return this.vuexStore
      .dispatch('users/updateCartItem', cartItem)
      .then(() => {
        return this._cacheOrderItemsToLocalForage()
      })
  }

  removeCartItem(cartItem) {
    return this.vuexStore
      .dispatch('users/removeCartItem', cartItem)
      .then(() => {
        return this._cacheOrderItemsToLocalForage()
      })
  }

  confirmItems(shippingMethodId, enableBonusDeductible) {
    return this.vuexStore
      .dispatch('users/confirmItems', {
        shippingMethodId,
        enableBonusDeductible
      })
      .then(() => {
        return this._cacheOrderItemsToLocalForage()
      })
  }

  placeOrder(order) {
    return this.vuexStore.dispatch('users/placeOrder', order).then(() => {
      return this._cacheOrderItemsToLocalForage()
    })
  }

  makePayment(order) {
    return this.vuexStore
      .dispatch('orders/makePayment', order)
      .then((_) => {
        return this._clearCacheFromLocalForage()
      })
      .then((_) => {
        return this.promoterEventStorageService.clearPromoterShareToken()
      })
  }

  expressMap(orderShipment, options = {}) {
    return this.vuexStore
      .dispatch('orderShipments/expressMap', {
        model: orderShipment,
        options
      })
      .then((_) => {
        return this._clearCacheFromLocalForage()
      })
  }

  // ======================================================================== //

  _fetchOrderItemFromServer() {
    return this.vuexStore
      .dispatch('users/currentOrder')
      .then((response) => {
        return this.localForage.setItem(ORDER_INFO_KEY, response.data.data.id)
      })
      .then(() => {
        return this._cacheOrderItemsToLocalForage()
      })
  }

  _cacheOrderItemsToLocalForage() {
    return this.localForage
      .setItem(
        ORDER_ITEMS_KEY,
        this.vuexStore.getters['orderItems/rawEntities']
      )
      .then(() => {
        return this.localForage.setItem(
          ORDER_ITEMS_EXPIRES_IN_KEY,
          this._currentTime() + CACHE_PERIOD
        )
      })
  }

  _clearCacheFromLocalForage() {
    return this.localForage
      .removeItem(ORDER_ITEMS_KEY)
      .then(() => {
        return this.localForage.removeItem(ORDER_ITEMS_EXPIRES_IN_KEY)
      })
      .then(() => {
        return this.localForage.removeItem(ORDER_INFO_KEY)
      })
  }

  _currentTime() {
    return Math.floor(Date.now() / 1000)
  }

  _tryResetCurrentOrderLocalCache() {
    if (Cookies.get(RESET_FLAG) === '1') {
      return this._clearCacheFromLocalForage().then(() => {
        Cookies.remove(RESET_FLAG)
      })
    } else {
      return Promise.resolve(true)
    }
  }

  _getPromoterShareToken() {
    this.promoterEventStorageService.getPromoterShareToken().then((token) => {
      this.promoterShareToken = token
    })
  }

  _mergeRequestBodyWithPromoterShareToken(requestBody) {
    if (!this.promoterShareToken) return requestBody

    requestBody.data.attributes.promoter_share_token = this.promoterShareToken

    return requestBody
  }
}
