<template lang="pug">
.vc-blog-card(:class="{ 'is-age-restricted': !!this.blog.is_age_restricted }")
  .inner
    a.blog-image(:href="blogLink")
      img.image(v-lazy="coverSrc")
      .age-restricted-indicator
        .age-restricted-message
          i.fa.fa-ban
          .text R-18
    .blog-info
      .blog-time
        | {{ dateTimeLocaleText(blog.published_at, (format = 'LL')) }}
      a.blog-title
        h4.inner-wrapper(:href="blogLink")
          | {{ blog.title }}
      .blog-description
        .description {{ blog.description() }}
      .options-wrapper
        .option-button
          a.button.is-default(:href="blogLink")
            span.text {{ copyLocaleText('read_blog') }}
</template>

<script>
const MOBILE_IMAGE_BREAK_POINT = 431

export default {
  // components: {},
  // mixins: [],
  props: {
    blog: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_IMAGE_BREAK_POINT
      )
    },

    blogLink() {
      return `/blogs/${this.blog.id}/${this.blog.slug}`
    },

    coverSrc() {
      return this.isMobile
        ? this.blog.mobile_cover.url || this.blog.cover.url
        : this.blog.cover.url
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
