<template lang="pug">
form.vc-order-address-form(@submit.prevent="")
  //- main content
  .section-label
    span {{ formSectionTitle }}
    a.form-function(
      v-if="addressType === 'billing' && isUserSignedIn"
      @click="openCommonAddressList"
    )
      .icon.is-small
        i.fa.fa-list-alt
      span.text {{ actionLocaleText('choose_form_common_addresses') }}

  .section-label(v-if="addressType === 'shipping'")
    b-checkbox(
      type="is-odd"
      v-model="form.is_same_as_billing_address"
      @input.native="flagFormAsEdited"
      v-if="ableToUseBillingInfoAsShippingInfo"
    ) {{ attributeLocaleText('order', 'is_same_as_billing_address') }}
    span(v-else)
    a.form-function(
      v-if="!form.is_same_as_billing_address && isUserSignedIn"
      @click="openCommonAddressList"
    )
      .icon.is-small
        i.fa.fa-list-alt
      span.text {{ actionLocaleText('choose_form_common_addresses') }}

  template(v-if="isAddressFormFieldsShow")
    .form-section.has-sub-fields
      b-field(
        :label="recipientLabelName"
        :type="errors.errorClassAt(addressFieldFor('recipient'))"
        :message="errors.get(addressFieldFor('recipient'))"
      )
        b-input(
          type="text"
          name="name"
          autocomplete="name"
          v-model="form[`${addressType}_address`].recipient"
          @input="errors.clear(addressFieldFor('recipient'))"
          @keyup.native="tryResetSelectedAddress"
        )
      b-field(
        :label="attributeLocaleText('user/common_address', 'phone')"
        :type="errors.errorClassAt(addressFieldFor('phone'))"
        :message="errors.get(addressFieldFor('phone'))"
      )
        p.control.calling-code(v-if="shippingType === 'oversea'")
          b-dropdown(
            v-model="form[`${addressType}_address`].dial_code"
            :max-height="200"
            scrollable
            @input="errors.clear(addressFieldFor('phone'))"
          )
            b-button.button(
              slot="trigger"
              icon-right="caret-down"
              outlined
              :type="errors.errorClassAt(addressFieldFor('phone'))"
            )
              span {{ form[`${this.addressType}_address`].dial_code }}
            b-dropdown-item(custom)
              b-input(
                v-model="searchCallingCodes"
                placeholder="search"
                expanded
              )
            b-dropdown-item(
              v-for="(code, index) in countryCallingCodes"
              :key="index"
              :value="code.dial_code"
              aria-role="listitem"
            ) {{ `${code.emoji} ${code.name} ${code.dial_code}` }}
        b-input(
          type="tel"
          autocomplete="tel"
          v-model="form[`${addressType}_address`].phone"
          @input="errors.clear(addressFieldFor('phone'))"
          @keyup.native="tryResetSelectedAddress"
        )
      template(v-if="addressType === 'billing'")
        template(v-if="invoiceServiceEnabled")
          b-field(
            :label="attributeLocaleText('user/common_address', 'invoice_type')"
            :type="errors.errorClassAt(addressFieldFor('invoice_type'))"
            :message="errors.get(addressFieldFor('invoice_type'))"
            :class="{ 'is-full': !carrierRequiredColumn }"
          )
            b-select(
              v-model="form.billing_address.address.invoice_type"
              @input="errors.clear(addressFieldFor('invoice_type')); tryResetSelectedAddress()"
            )
              option(
                v-for="(invoiceType, index) in invoiceTypes"
                :key="index"
                :value="invoiceType.key"
              ) {{ invoiceType.name }}
          b-field(
            v-if="carrierRequiredColumn === 'carrier_number'"
            :label="attributeLocaleText('user/common_address', 'carrier_number')"
            :type="errors.errorClassAt(addressFieldFor('carrier_number'))"
            :message="errors.get(addressFieldFor('carrier_number'))"
          )
            b-input(
              v-model="form.billing_address.address.carrier_number"
              @input="errors.clear(addressFieldFor('carrier_number'))"
              @keyup.native="tryResetSelectedAddress"
            )
          b-field.love-code(
            v-if="carrierRequiredColumn === 'love_code'"
            :type="errors.errorClassAt(addressFieldFor('love_code'))"
            :message="errors.get(addressFieldFor('love_code'))"
          )
            a.label(
              href="#"
              @click.prevent="openLoveCodeSelector"
            )
              span {{ attributeLocaleText('user/common_address', 'love_code') }}
              span.select-code
                .icon
                  i.fa.fa-search
                span 捐贈碼查詢
            b-input(
              v-model="form.billing_address.address.love_code"
              @input="errors.clear(addressFieldFor('love_code'))"
              @keyup.native="tryResetSelectedAddress"
              @click.native="openLoveCodeSelector"
              disabled
            )
        template(v-if="companyAndEinEnabled")
          b-field(
            :label="attributeLocaleText('user/common_address', 'company') + ` (${messageLocaleText('optional')})`"
            :type="errors.errorClassAt(addressFieldFor('company'))"
            :message="errors.get(addressFieldFor('company'))"
          )
            b-input(
              type="text"
              name="company"
              autocomplete="organization"
              v-model="form[`${addressType}_address`].company"
              @input="errors.clear(addressFieldFor('company'))"
              @keyup.native="tryResetSelectedAddress"
            )
          b-field(
            :label="attributeLocaleText('user/common_address', 'ein') + ` (${messageLocaleText('optional')})`"
            :type="errors.errorClassAt(addressFieldFor('ein'))"
            :message="errors.get(addressFieldFor('ein'))"
          )
            b-input(
              type="text"
              v-model="form[`${addressType}_address`].ein"
              autocomplete="ein"
              @input="errors.clear(addressFieldFor('ein'))"
              @keyup.native="tryResetSelectedAddress"
            )

      template(v-if="isRequireAddressField")
        template(v-if="isSimpleAddress")
          b-field(
            :label="attributeLocaleText('order/address', 'zip_code')"
            :type="errors.errorClassAt(addressFieldFor('zip'))"
            :message="errors.get(addressFieldFor('zip'))"
          )
            b-input(
              v-model="form[`${addressType}_address`].address.zip"
              autocomplete="street-address"
              @input="errors.clear(addressFieldFor('zip'))"
              @keyup.native="tryResetSelectedAddress"
            )
        template(v-if="!isSimpleAddress")
          b-field(
            :label="attributeLocaleText('user/common_address', 'city')"
            :type="errors.errorClassAt(addressFieldFor('city'))"
            :message="errors.get(addressFieldFor('city'))"
          )
            b-select(
              v-model="form[`${addressType}_address`].address.city"
              autocomplete="locality"
              @input="errors.clear(addressFieldFor('city'))"
            )
              option(
                value=""
                disabled
                selected
              ) {{ actionLocaleText('choose_one') }}
              option(
                v-for="(city, index) in cities"
                :key="index"
                :value="city"
              ) {{ city }}
          b-field(
            :label="attributeLocaleText('user/common_address', 'area')"
            :type="errors.errorClassAt(addressFieldFor('area'))"
            :message="errors.get(addressFieldFor('area'))"
          )
            b-select(
              v-model="form[`${addressType}_address`].address.area"
              autocomplete="region"
              @input="errors.clear(addressFieldFor('area'))"
            )
              option(
                value=""
                disabled
                selected
              ) {{ actionLocaleText('choose_one') }}
              option(
                v-for="(area, index) in areas"
                :key="index"
                :value="area"
              ) {{ area }} ({{ getZipCode(area) }})

        b-field.is-full(
          :label="attributeLocaleText('user/common_address', isSimpleAddress ? 'address' : 'street_address')"
          :type="errors.errorClassAt(addressFieldFor('street_address'))"
          :message="errors.get(addressFieldFor('street_address'))"
        )
          b-input(
            type="text"
            v-model="form[`${addressType}_address`].address.street_address"
            autocomplete="street-address"
            @input="errors.clear(addressFieldFor('street_address'))"
            @keyup.native="tryResetSelectedAddress"
          )

      .field.is-full.checkbox-wrapper(v-if="isSaveAsCommonAddressOptionShow")
        b-checkbox(
          type="is-odd"
          size="is-small"
          v-model="form[`save_${addressType}_address_as_common`]"
        )
          | {{ actionLocaleText('save_as_common_address') }}
      .field.is-full.checkbox-wrapper(v-if="allowPrintInvoiceWithOrder")
        b-checkbox(
          type="is-odd"
          size="is-small"
          v-model="form[`${addressType}_address`].address.print_invoice_with_order"
        )
          | {{ attributeLocaleText('user/common_address', 'print_invoice_with_order') }}
</template>

<script>
import isEqual from 'lodash.isequal'
import TaiwanZipCodes from '@services/taiwan_zip_codes.json'
import LoveCodeSelector from '@applicationComponents/common/love-code-selector.vue'
import CommonAddressList from '../common_address/list.vue'
import useCallingCodesDropdown from '../../../../shared/composables/use_calling_codes_dropdown.js'

export default {
  components: {
    CommonAddressList
  },

  setup() {
    const { searchCallingCodes, countryCallingCodes } =
      useCallingCodesDropdown()

    return {
      searchCallingCodes,
      countryCallingCodes
    }
  },

  // mixins: [],
  props: {
    form: {
      type: Object,
      required: true
    },

    addressType: {
      type: String,
      required: true,
      validator: (value) => {
        return ['shipping', 'billing'].includes(value)
      }
    },

    shippingMethod: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      zipCodes: TaiwanZipCodes,
      selectedAddress: undefined,
      isFormEdited: false
    }
  },

  computed: {
    companyAndEinEnabled() {
      if (this.invoiceTypes.length === 0) return true

      return !this.form[`${this.addressType}_address`].address.invoice_type
    },

    recipientLabelName() {
      return this.attributeLocaleText(
        'user/common_address',
        this.addressType === 'billing' ? 'buyer_name' : 'recipient'
      )
    },

    isRequireAddressField() {
      if (this.addressType === 'billing') return true

      return (
        !this.shippingMethod.isStoreLogistic() &&
        !this.shippingMethod.isCvsLogistic()
      )
    },

    ableToUseBillingInfoAsShippingInfo() {
      return (
        this.addressType === 'shipping' &&
        !this.shippingMethod.isStoreLogistic() &&
        !this.shippingMethod.isCvsLogistic()
      )
    },

    shippingType() {
      return this.shippingMethod.shipment_type_detail.shipping_type
    },

    formSectionTitle() {
      if (
        this.addressType === 'shipping' &&
        !this.shippingMethod.isHomeLogistic()
      )
        return this.attributeLocaleText('order', 'pick_up_info')

      return this.attributeLocaleText('order', `${this.addressType}_address`)
    },

    cities() {
      return Object.keys(TaiwanZipCodes)
    },

    areas() {
      if (
        this.form[`${this.addressType}_address`].address.city &&
        TaiwanZipCodes[this.form[`${this.addressType}_address`].address.city]
      )
        return Object.keys(
          TaiwanZipCodes[this.form[`${this.addressType}_address`].address.city]
        )
    },

    errors() {
      return this.$store.getters['users/errors']
    },

    isSaveAsCommonAddressOptionShow() {
      return this.isFormEdited && !this.selectedAddress && this.isUserSignedIn
    },

    isAddressFormFieldsShow() {
      return (
        this.addressType === 'billing' || !this.form.is_same_as_billing_address
      )
    },

    extraDataForCommonAddress() {
      if (this.addressType === 'shipping') return this.shippingType

      return this.shippingType === 'oversea' ? 'oversea_billing' : 'billing'
    },

    isSimpleAddress() {
      return this.shippingMethod.isOverseaLogistic()
    },

    invoiceTypes() {
      return this.$store.getters['siteConfigs/invoiceTypes']
    },

    invoiceServiceEnabled() {
      return this.$store.getters['siteConfigs/invoiceServiceEnabled']
    },

    carrierRequiredColumn() {
      return this.invoiceTypes.find(
        (type) => type.key === this.form.billing_address.address.invoice_type
      )?.required_column
    },

    allowPrintInvoiceWithOrder() {
      // 邏輯調整請全域搜尋：隨貨附發票相關設定
      return false // comment this line if needed
      if (this.addressType !== 'billing') return false
      if (!this.invoiceServiceEnabled) return false

      return true // 一律開啟
      return !this.form.billing_address.address.invoice_type // 有載具或愛心碼才開啟
    }
  },

  watch: {
    selectedAddress: {
      handler(newAddress, oldAddress) {
        if (!newAddress) return

        this.flagFormAsEdited()
        this.form[`${this.addressType}_address`].recipient =
          newAddress.recipient
        this.form[`${this.addressType}_address`].dial_code =
          newAddress.dial_code
        this.form[`${this.addressType}_address`].phone = newAddress.phone
        this.form[`${this.addressType}_address`].company = newAddress.company
        this.form[`${this.addressType}_address`].ein = newAddress.ein
        this.$set(
          this.form[`${this.addressType}_address`],
          'address',
          newAddress.address
        )
      }
    },

    isFormEdited: {
      handler(newValue) {
        this.form[`save_${this.addressType}_address_as_common`] = true
      }
    },

    'form.shipping_address.address.city': {
      handler(newCity) {
        if (TaiwanZipCodes[newCity]?.[this.form.shipping_address.address.area])
          return

        this.$set(this.form.shipping_address.address, 'area', '')
      }
    },

    'form.billing_address.address.city': {
      handler(newCity) {
        if (TaiwanZipCodes[newCity]?.[this.form.billing_address.address.area])
          return

        this.$set(this.form.billing_address.address, 'area', '')
      }
    },

    'form.shipping_address.address.area': {
      handler(newArea) {
        if (newArea)
          this.form.shipping_address.address.zip =
            TaiwanZipCodes[this.form.shipping_address.address.city][
              this.form.shipping_address.address.area
            ]
      }
    },

    'form.billing_address.address.area': {
      handler(newArea) {
        if (newArea)
          this.form.billing_address.address.zip =
            TaiwanZipCodes[this.form.billing_address.address.city][
              this.form.billing_address.address.area
            ]
      }
    },

    'form.billing_address.address.invoice_type': {
      handler(newType) {
        if (newType === null) {
          this.form.billing_address.address.carrier_number = ''
          this.form.billing_address.address.love_code = ''
        } else {
          this.form.billing_address.company = ''
          this.form.billing_address.ein = ''
        }
      }
    }
  },
  // created() {},
  mounted() {
    this.form[`${this.addressType}_address`].address.extra_data =
      this.extraDataForCommonAddress

    if (
      this.addressType === 'shipping' &&
      !isEqual(this.form.shipping_address, this.form.billing_address)
    )
      this.form.is_same_as_billing_address = false
  },

  methods: {
    openCommonAddressList() {
      this.$buefy.modal.open({
        parent: this,
        component: CommonAddressList,
        props: {
          selectedAddress: this.selectedAddress,
          logisticType: this.extraDataForCommonAddress
        },
        events: {
          'update:selectedAddress': (address) =>
            (this.selectedAddress = address)
        }
      })
    },

    tryResetSelectedAddress() {
      this.selectedAddress = undefined
      this.flagFormAsEdited()
    },

    getZipCode(area) {
      return TaiwanZipCodes[
        this.form[`${this.addressType}_address`].address.city
      ][area]
    },

    flagFormAsEdited() {
      this.isFormEdited = true
      this.$emit('form-edited')
    },

    addressFieldFor(field) {
      return this.addressType === 'shipping' ? field : `b_${field}`
    },

    openLoveCodeSelector() {
      this.$buefy.modal.open({
        parent: this,
        component: LoveCodeSelector,
        props: {
          selectedLoveCode:
            this.form[`${this.addressType}_address`].address.love_code
        },
        events: {
          'update:love-code-selected': (loveCode) => {
            if (this.form.billing_address.address !== loveCode)
              this.tryResetSelectedAddress()
            this.$set(this.form.billing_address.address, 'love_code', loveCode)
          }
        }
      })
    }
  }
}
</script>
