<template lang="pug">
.vc-common-mobile-quick-link-wrapper(v-if="tabs")
  a.tab-wrapper(
    v-for="tab in tabs"
    :href="tab.link"
    target="_blank"
    ref="noopenner noreferrer"
  )
    .icon-wrapper(:style="`background: ${tab.background_color}`")
      .icon
        i.fa(:class="tab.icon")
    span.tab-title
      | {{ tab.title }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  // props: {},
  data() {
    return {}
  },

  computed: {
    configs() {
      return this.$store.getters['siteConfigs/allConfigs']
    },

    tabs() {
      if (!this.configs.mobile_quick_link_settings) return false
      return this.configs.mobile_quick_link_settings.tabs
    }
  },

  created() {
    if (!this.tabs) this.$store.dispatch('siteConfigs/fetchMobileQuickLinks')
  }
  // mounted() {},
  // methods: {}
}
</script>
