<template lang="pug">
.vc-order-shipping-method-selector(:class="{ 'is-confirmed': isConfirmed }")
  template(v-if="selectableShippingMethods.length > 0")
    .selectable-method-unit(
      v-for="method in selectableShippingMethods"
      v-if="isSelected(method) || !isConfirmed"
      :key="method.id"
      @click="selectShippingMethod(method)"
      :class="{ 'is-selected': isSelected(method) }"
    )
      .select-button
      .selectable-method
        .icon
          i.fa.fa-truck
        span {{ method.name }}

    .shipping-method-description(v-if="selectedShippingMethod")
      .content(v-html="simpleFormat(selectedShippingMethod.description)")
      .shipping-rate-notice-message(v-if="!isConfirmed")
        span {{ selectedShippingMethod.displayRateRule() }}

  template(v-else)
    b-message(
      type="is-info"
      size="is-small"
      has-icon
    ) {{ messageLocaleText('help.order_items_can_not_shipping_together_due_to_conflict_of_shipping_categories') }}
</template>

<script>
import _intersection from 'lodash.intersection'

export default {
  // components: {},
  // mixins: [],
  props: {
    cartItems: {
      type: Array,
      required: true
    },

    selectedShippingMethodId: {
      type: [String, Number],
      required: false
    },

    isConfirmed: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    variants() {
      return this.cartItems.map((item) =>
        this.$store.getters['productVariants/find'](item.variant_id)
      )
    },

    selectableShippingMethods() {
      return _intersection(
        ...this.variants.map((variant) =>
          variant.shipping_methods.map((method) => method.id)
        )
      ).map((id) => this.$store.getters['shippingMethods/find'](id))
    },

    selectedShippingMethod() {
      return this.selectableShippingMethods.find(
        (method) => method.id === this.selectedShippingMethodId
      )
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    selectShippingMethod(method) {
      if (this.isConfirmed) return

      this.$emit('update:selectedShippingMethodId', method.id)
      this._sendGaCheckoutOptionEvent(method)
    },

    isSelected(method) {
      return method.id === this.selectedShippingMethodId
    },

    _sendGaCheckoutOptionEvent(method) {
      return this.$store.dispatch('gaOperation', [
        [
          'ec:setAction',
          'checkout_option',
          {
            step: 1,
            option: method.name
          }
        ],
        [
          'send',
          {
            hitType: 'event',
            eventCategory: 'Ecommerce',
            eventAction: 'Select Shipping Method'
          }
        ]
      ])
    }
  }
}
</script>
