<template lang="pug">
.vc-product-recommend-products(:id="elementId")
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )
  .section-title(v-if="products.length > 0")
    | {{ copyLocaleText('recommend_products') }}
  .products-wrapper
    product-card(
      v-for="(product, index) in products"
      :key="index"
      :product="product"
      :index="index"
      :list-location="listLocation"
      :id-prefix="targetProduct ? null : targetProduct.id"
    )
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useStore, useInstance } from 'skid-composables'
import ProductCard from './card.vue'
import inView from 'in-view'

const props = defineProps({
  productsCount: {
    type: Number,
    required: false,
    default: () => 4
  },

  listLocation: {
    type: String,
    required: false,
    default: () => 'Recommend Products'
  },

  targetProduct: {
    type: Object,
    required: false
  }
})

const store = useStore()
const currentInstance = useInstance()
const elementId = `recommend-products-${currentInstance._uid}`
const isLoading = ref(false)
const recommendProductIds = ref([])
const products = computed(() => {
  return recommendProductIds.value.map((id) =>
    store.getters['products/find'](id)
  )
})

const fetchProducts = async () => {
  isLoading.value = true

  recommendProductIds.value = await store
    .dispatch('products/hotProducts', {
      pageNumber: 1,
      pageSize: props.productsCount,
      search: {
        id_not_in: props.targetProduct?.id,
        categories_id_in: props.targetProduct?.category_ids
      }
    })
    .then((result) => result.data.data.map((product) => product.id))

  isLoading.value = false
}

onMounted(() => {
  inView(`#${elementId}`).once('enter', fetchProducts)
})
</script>
