<template lang="pug">
a.vc-promotional-article-product-name-trigger.trigger(
  href="#"
  @click.prevent="toggleParentClass"
)
  img.svg(:src="currentTriggerSvg")
</template>

<script>
import showDescSvg from '@/application/images/show-desc.svg'
import hideDescSvg from '@/application/images/hide-desc.svg'

export default {
  data() {
    return {
      showingProductName: true
    }
  },

  computed: {
    currentTriggerSvg() {
      return this.showingProductName ? showDescSvg : hideDescSvg
    }
  },

  methods: {
    toggleParentClass() {
      this.showingProductName = !this.showingProductName
      this.$el.parentElement.classList.toggle(
        'is-hidden-product-name',
        !this.showingProductName
      )
    }
  }
}
</script>
