<template lang="pug">
.vc-common-love-code-selector
  b-field(
    label="捐贈碼單位查詢"
    horizontal
  )
    b-input(
      v-model="search"
      icon="search"
    )
  .unit-wrapper
    b-loading(
      :active.sync="isLoading"
      :is-full-page="false"
    )
    a.unit(
      v-for="(info, index) in filteredTaiwanLoveCodes"
      v-if="index < displaySize"
      href="#"
      @click.prevent="select(info)"
      :class="{ selected: info.code === selectedLoveCode }"
    )
      .code {{ info.code }}
      .name {{ info.name }}
  .footer
    b-button(
      @click="displayMore"
      size="is-small"
      :disabled="moreDisabled"
    )
      | {{ actionLocaleText('more', { model: '...' }) }}
</template>

<script>
import TaiwanLoveCodes from '@services/taiwan_love_codes.json'
import debounce from 'lodash.debounce'

const DEFAULT_DISPLAY_SIZE = 10

export default {
  // components: {},
  // mixins: [],
  props: {
    selectedLoveCode: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      search: this.selectedLoveCode,
      debounceSearch: this.selectedLoveCode,
      displaySize: DEFAULT_DISPLAY_SIZE,
      isLoading: false
    }
  },

  computed: {
    taiwanLoveCodes() {
      return TaiwanLoveCodes
    },

    filteredTaiwanLoveCodes() {
      if (!this.debounceSearch) {
        return this.taiwanLoveCodes
      }

      return this.taiwanLoveCodes.filter((info) => {
        return (
          info.code.indexOf(this.debounceSearch) > -1 ||
          info.name.indexOf(this.debounceSearch) > -1
        )
      })
    },

    moreDisabled() {
      return this.filteredTaiwanLoveCodes.length < this.displaySize
    }
  },

  watch: {
    search: {
      handler(newValue) {
        this.setDebounceSearch(newValue)
        this.isLoading = true
      }
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    select(info) {
      this.$emit('update:love-code-selected', info.code)
      this.$parent.close()
    },

    setDebounceSearch: debounce(function (newValue) {
      this.displaySize = DEFAULT_DISPLAY_SIZE
      this.debounceSearch = newValue
      this.isLoading = false
    }, 500),

    displayMore() {
      this.displaySize += 5
      this._scrollToBottom()
    },

    _scrollToBottom() {
      const body = this.$el.querySelector('.unit-wrapper')

      this.$nextTick(() => {
        body.scroll({
          top: body.scrollHeight,
          behavior: 'smooth'
        })
      })
    }
  }
}
</script>
