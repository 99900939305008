<template lang="pug">
.vc-info-link-use-type-container
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  swiper(:options="swiperOptions")
    swiper-slide(
      v-for="infoLink in infoLinks"
      :key="`${useType}-${infoLink.id}`"
    )
      info-box(:info-link="infoLink")
    .swiper-pagination(slot="pagination")
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import {
  swiper as Swiper,
  swiperSlide as SwiperSlide,
  useStore,
  useSwiper
} from 'skid-composables'
// components 只需要 import 就可以在 template 用了
import InfoBox from './info-box.vue'

// 使用 defineProps 來定義 properties
const props = defineProps({
  useType: {
    type: String,
    required: true
  }
})

const { swiperOptions } = useSwiper({
  effect: 'cards',
  slidesPerView: '3',
  autoplay: false,
  breakpoints: {
    769: {
      slidesPerView: 'auto'
    }
  }
})

const store = useStore()
const infoLinks = computed(() => {
  return store.getters['infoLinks/allByType'](props.useType)
})

const isLoading = ref(true)
onMounted(async () => {
  await store.dispatch('infoLinks/all', {
    keep: true,
    filter: props.useType,
    sort: 'position'
  })

  isLoading.value = false
})
</script>
