<template lang="pug">
b-modal.vc-modal-link-pop-modal(:active.sync="isImageModalActive")
  b-carousel(
    :pause-info="false"
    :interval="7000"
    :arrow="false"
    :indicator-inside="false"
  )
    b-carousel-item(
      v-for="(data, index) in modalLinks"
      :key="index"
    )
      a.link(:href="data.url")
        img.img(
          :src="isMobile ? data.mobile_image.url || data.image.url : data.image.url"
          :alt="data.alt_text"
        )
</template>

<script>
const MOBILE_IMAGE_BREAK_POINT = 769

export default {
  // components: {},
  // mixins: [],
  props: {
    useType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isImageModalActive: false
    }
  },

  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_IMAGE_BREAK_POINT
      )
    },

    modalLinks() {
      return this.$store.getters['modalLinks/allByType'](this.useType)
        .filter((link) => link.isLive)
        .sort((a, b) => a.position - b.position)
    }
  },

  // created() {},
  mounted() {
    this.$store
      .dispatch('modalLinks/allByUseType', {
        filter: this.useType
      })
      .then((_) => {
        if (this.modalLinks.length > 0) this.isImageModalActive = true
      })
  }
  // methods: {}
}
</script>
