<template lang="pug">
.vc-product-info-card-mobile.is-mobile-only(v-if="product.isDataLoaded()")
  .product-info
    a.brand(:href="brandLink")
      | {{ product.brand_name }}
    .name
      | {{ product.name }}
  .price-info
    .label
      span {{ attributeLocaleText('product', 'price') }}
    .price
      span(:data-currency="currentCurrency")
        | {{ toMoney(product.original_price).format() }}
      span(:data-currency="currentCurrency")
        | {{ variantShipPrice.format() }}
  .sticky-action-bar(:style="{ 'padding-bottom': paddingBottom }")
    purchase-button(
      :promoter-share="promoterShare"
      :product="product"
    )
</template>

<script>
import promoterEventVariantMixin from '../mixins/promoter_event_variant_mixin.js'
import PurchaseButton from './purchase-button.vue'
import Countdown from '@chenfengyuan/vue-countdown'
import InstagramBarResponseMixin from '../mixins/instagram_bar_response_mixin.js'

export default {
  components: {
    PurchaseButton,
    Countdown
  },

  mixins: [promoterEventVariantMixin, InstagramBarResponseMixin],

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      paddingBottom: null
    }
  },

  computed: {
    brandLink() {
      return `/brands/${this.product.brand_id}`
    },

    _variantShipCompareTarget() {
      return this.product
    }
  },

  // created() {},

  mounted() {},

  methods: {
    onScrollDownForInstagram() {
      this.paddingBottom = null
    },

    onScrollUpForInstagram() {
      this.paddingBottom = `${this.instagramBarHeight + 24}px`
    }
  }
}
</script>
