<template lang="pug">
.vc-common-locale-config-form.vc-common-currency-config-form
  .form-header
    .title {{ copyLocaleText('language_settings') }}
  .form-body
    b-select.is-extended(
      v-model="currentLocale"
      expanded
    )
      option(
        v-for="(key, index) in availableLocales"
        :key="index"
        :value="key"
      )
        | {{ copyLocaleText(`locales.${key}`) }}

  .form-footer.bottons
    button.button(@click="vueInstance.$parent.close()")
      | {{ actionLocaleText('close') }}
    button.button.is-odd(@click="confirm")
      | {{ actionLocaleText('confirm') }}
</template>

<script setup lang="ts">
import LocalePreferenceService from '@services/locale_preference_service.js'
import { ref } from 'vue'
import { useInstance, useStore, useI18n } from 'skid-composables'

const store = useStore()
const service = new LocalePreferenceService(store)
const vueInstance = useInstance()
const availableLocales = service.availableLocales
const { copyLocaleText, actionLocaleText } = useI18n()
const currentLocale = ref(I18n.locale)

const confirm = () => service.updateLocale(currentLocale.value)
</script>
