<template lang="pug">
.vc-sales-event-product-unit(
  :class="{ 'is-age-restricted': shouldBlurOutCover }"
  :id="anchorId"
)
  .inner
    a.product-image(@click="visitProductPage")
      img.image(v-lazy="product.coverImageThumb()")
      .indicator
        | {{ discountRate }}
        | {{ attributeLocaleText('product', 'discount_unit') }}
      .age-restricted-indicator
        .age-restricted-message
          i.fa.fa-ban
          .text R-18

    .info-wrapper
      .product-name
        .name {{ product.name }}
        .brand(
          v-if="product.brand_name"
          key="product.brand_name"
        ) {{ product.brand_name }}
        .brand.placeholder(
          v-else
          key="product.brand_name"
        )
          | &nbsp;
      .price-info
        //- //- if has no discount, this placeholder should hold up height.
        //- .price-type.placeholder
        //-   span &nbsp;

        .price-type
          span.type {{ copyLocaleText('msrp') }}
          span.price(:data-currency="currentCurrency") {{ toMoney(product.original_price).format() }}
        .price-type.-event-live
          span.type {{ copyLocaleText('event_price') }}
          span.price(:data-currency="currentCurrency") {{ eventPrice.format() }}

      //- .description
        | {{ product.card_description }}

      .options-wrapper(v-if="salesEvent.isLive()")
        purchase-button(
          :product="product"
          :sales-event="salesEvent"
          :index="index"
          :list-location="listLocation"
        )
</template>

<script>
import productCardMixin from '../mixins/product_card_mixin.js'
import salesEventPricesMixin from '../mixins/sales_event_prices_mixin.js'
import PurchaseButton from './purchase-button.vue'

export default {
  components: {
    PurchaseButton
  },

  mixins: [productCardMixin, salesEventPricesMixin],

  // props: {},

  // data() {},

  computed: {
    productLink() {
      const basicPath = this.salesEvent.is_hidden
        ? `/sales-events/s/${this.salesEvent.token}`
        : `/sales-events/${this.salesEvent.id}`

      return `${basicPath}/products/${this.product.slug || this.product.id}`
    },

    _eventPriceCompareTarget() {
      return this.product
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    visitProductPage(event) {
      this.clickHandler(event)
      if (this.salesEvent.isLive()) return Turbolinks.visit(this.productLink)

      this.$store.dispatch('addFlashMessage', [
        'notice',
        this.messageLocaleText('help.sales_event_not_live_currently')
      ])
    },

    sendGaImpressionEvent() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:addImpression',
            {
              id: this.product.sku,
              name: this.product.name,
              brand: this.product.brand_name,
              list: this.listLocation,
              position: this.index + 1,
              price: this.eventPrice.amount
            }
          ]
        ])
        .then((_) => {
          this.$emit('ga-impressed')
        })
    },

    sendGaClickEvent() {
      return this.$store.dispatch('gaOperation', [
        [
          'ec:addProduct',
          {
            id: this.product.sku,
            name: this.product.name,
            brand: this.product.brand_name,
            position: this.index + 1,
            price: this.eventPrice.amount
          }
        ],
        [
          'ec:setAction',
          'click',
          {
            list: this.listLocation
          }
        ],
        [
          'send',
          {
            hitType: 'event',
            eventCategory: 'UX',
            eventAction: 'click',
            eventLabel: this.listLocation,
            transport: 'beacon'
          }
        ]
      ])
    }
  }
}
</script>
