<template lang="pug">
.vc-order-addresses-and-extra-info
  .info-column.left
    address-info-unit(
      :order="order"
      address-type="billing"
    )
    address-info-unit(
      :order="order"
      address-type="shipping"
    )
    .info-section
      .section-label
        span.text {{ modelNameLocaleText('shipping_method') }}
      .vc-order-shipping-method-selector.is-confirmed
        .selectable-method-unit.is-selected
          .select-button
          .selectable-method
            .icon
              i.fa.fa-truck
            span {{ orderShipment.gateway_info.method_name }}

  .info-column.right
    .info-section
      .section-label
        span.text {{ modelNameLocaleText('payment_method') }}

      .vc-order-payment-method-selector.is-confirmed
        .selectable-method-unit.is-selected
          .select-button
          .selectable-method
            .icon
              i.fa(
                :class="methodIconClass(orderPayment.gateway_info.payment_type)"
              )
            span {{ orderPayment.gateway_info.method_name }}
          .selectable-card(v-if="userCreditCard")
            span(v-if="userCreditCard.last_four") {{ userCreditCard.fullCardNo() }}
          span(v-if="paymentAsyncCode")
            span ：
            span(style="color: red") {{ paymentAsyncCode }}

    .info-section(v-if="orderInvoice")
      .section-label
        span.text.invoice-label {{ modelNameLocaleText('order/invoice') }}
        .tag {{ aasmStateLocaleText('order/invoice', 'aasm_state', orderInvoice.aasm_state) }}
      .section-content
        .info-unit
          .label {{ attributeLocaleText('order/invoice', 'invoice_number') }}
          .info-content {{ orderInvoice.invoice_number }}
        .info-unit
          .label {{ attributeLocaleText('order/invoice', 'random_number') }}
          .info-content {{ orderInvoice.random_number }}
        .info-unit
          .label {{ attributeLocaleText('order/invoice', 'create_time') }}
          .info-content {{ dateTimeLocaleText(orderInvoice.create_time) }}

    .info-section(v-if="creditNotes.length > 0")
      .section-label
        span.text {{ modelNameLocaleText('order/credit_note') }}
      .section-content(v-for="creditNote in creditNotes")
        .info-unit
          .label {{ attributeLocaleText('order/credit_note', 'number') }}
          .info-content {{ creditNote.number }}
        .info-unit
          .label {{ attributeLocaleText('order/credit_note', 'remain_amount') }}
          .info-content {{ toMoney(creditNote.remain_amount, { isExchange: false }).format() }}

    .info-section(v-if="order.notes")
      .section-label
        span.text {{ attributeLocaleText('order', 'notes') }}
      .section-content
        .order-notes {{ order.notes }}

    .info-section
      .section-label
        span.text {{ copyLocaleText('checkout_detail') }}
      price-detail(:order="order")
</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import AddressInfoUnit from './address-info-unit.vue'
import ShippingMethodSelector from './shipping-method-selector.vue'

export default {
  components: {
    AddressInfoUnit,
    ShippingMethodSelector
  },

  mixins: [checkoutFlowMixin],

  props: {
    currentStep: {
      required: false
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    orderShipment() {
      return this.$store.getters['orderShipments/find'](
        this.order.shipments[0].id
      )
    },

    paymentAsyncCode() {
      if (
        !this.orderPayment.latest_notification ||
        this.orderPayment.state != 'pending'
      )
        return
      return this.orderPayment.latest_notification.infomation.code
    },

    userCreditCard() {
      return this.$store.getters['userCreditCards/find'](
        this.orderPayment.gateway_info.user_credit_card_id
      )
    }
  },
  // created() {},
  mounted() {
    if (this.orderPayment.gateway_info.user_credit_card_id) {
      this.$store.dispatch(
        'userCreditCards/find',
        this.orderPayment.gateway_info.user_credit_card_id
      )
    }
  },
  methods: {
    methodIconClass(paymentType) {
      return {
        'fa-credit-card':
          ['credit_card', 'line_pay', 'jko_pay'].indexOf(paymentType) > -1,
        'atm-icon': paymentType === 'vacc'
      }
    }
  }
}
</script>
