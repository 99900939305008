import flatten from 'lodash.flatten'

export default {
  // components: {},
  props: {
    promoterShare: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    _variantShipCompareTarget() {
      console.log(
        'please implement _variantShipCompareTarget computed property first. It must be an instance of Product or ProductVariant.'
      )
    },

    promoterEvent() {
      return (
        this.promoterShare &&
        this.$store.getters['promoterEvents/find'](this.promoterShare.event_id)
      )
    },

    eventVariantShips() {
      return this.$store.getters['promoterEventVariantShips/all']
    },

    shareVariantShips() {
      return this.$store.getters['promoterShareVariantShips/all']
    },

    selectedEventVariantShip() {
      return this.eventVariantShips.find((eventVariantShip) => {
        if (this._variantShipCompareTarget.master) {
          // for product
          return (
            eventVariantShip.variant_id ===
            parseInt(this._variantShipCompareTarget.master.id)
          )
        }

        // for variant
        return (
          eventVariantShip.variant_id ===
          parseInt(this._variantShipCompareTarget.id)
        )
      })
    },

    selectedShareVariantShip() {
      return this.shareVariantShips.find(
        (shareVariantShip) =>
          shareVariantShip.event_variant_ship_id ===
          parseInt(this.selectedEventVariantShip.id)
      )
    },

    variantShipPrice() {
      if (this.selectedEventVariantShip) {
        return this.toMoney(this.selectedEventVariantShip.price)
      } else {
        return this.toMoney(this._variantShipCompareTarget.consumer_price)
      }
    },

    discountRate() {
      let rateNumber = (this.variantShipPrice.cents / this.originalPriceCents)
        .toFixed(2)
        .toString()
        .split('.')[1]

      if (rateNumber.slice(-1) === '0') rateNumber = rateNumber.substr(0, 1)
      return rateNumber
    },

    originalPriceCents() {
      return this.toMoney(this.product.original_price).cents
    },

    hasDiscountRate() {
      return this.variantShipPrice.cents < this.originalPriceCents
    }
  }
  // methods: {}
}
